<template>
    <Main class="background" :back="true">
        <Header></Header>
        <Block>
            <Breadcrumb :to="to"></Breadcrumb>
            <div class="detail">
                <!-- <div class="left">
                    <img :src="detail.logo" />
                </div> -->
                <div class="left" v-if="detail.logo" >
                    <el-image class="img" fit="contain" :src="detail.logo" :title="getLanguageData({zh: detail.company_name, en: detail.company_en_name})">
                    </el-image>
                </div>
                <div class="content">
                    <div class="line1">
                        <div class="name ellipsis" :title="getLanguageData({zh: detail.company_name, en: detail.company_en_name})">{{getLanguageData({zh: detail.company_name, en: detail.company_en_name})}}</div>
                        <div class="booth">
                            <div class="label">{{$t('exhibitor.booth')}}</div>
                            <div class="text ellipsis" :title="detail.booth">{{detail.booth}}</div>
                        </div>
                    </div>
                    <div class="line2">
                        <div class="line2-left" v-if="getLanguageData({zh: detail.contact, en: detail.contact_en})">
                            <div class="label">{{$t('exhibitor.contact')}}</div>
                            <div class="text">{{getLanguageData({zh: detail.contact, en: detail.contact_en})}}</div>
                        </div>
                        <div class="line2-left" v-if="detail.mobile">
                            <div class="label">{{$t('exhibitor.mobile')}}</div>
                            <div class="text">{{detail.mobile}}</div>
                        </div>
                        <div class="line2-left" v-if="detail.telephone">
                            <div class="label">{{$t('exhibitor.telephone')}}</div>
                            <div class="text">{{detail.telephone}}</div>
                        </div>
                    </div>
                    <div class="line2" v-if="getLanguageData({zh: detail.detailed_address, en: detail.detailed_en_address})">
                        <div class="label">{{$t('exhibitor.address')}}</div>
                        <div class="text ellipsis" :title="getLanguageData({zh: detail.detailed_address, en: detail.detailed_en_address})">{{getLanguageData({zh: detail.detailed_address, en: detail.detailed_en_address})}}</div>
                    </div>
                    <div class="line2" v-if="getLanguageData({zh: detail.intro, en: detail.intro_en})">
                        <div class="label">{{$t('exhibitor.intro')}}</div>
                        <div class="text">{{getLanguageData({zh: detail.intro, en: detail.intro_en})}}</div>
                    </div>
                </div>
                <div class="right" v-if="getLanguageData({zh: detail.video, en: detail.video_en})">
                    <div class="video">
                        <video width="268" height="150">
                            <source :src="getLanguageData({zh: detail.video, en: detail.video_en})" type="video/mp4">
                            {{$t('exhibitor.video_nonsupport')}}
                        </video>
                        <div class="play">
                            <img :src="require('@/assets/img/video-play.png')" @click="videoPlay=true" />
                        </div>
                    </div>
                </div>
            </div>
        </Block>

        <Block v-if="detail.toy.length > 0">
            <div class="operate">
                <div class="button-group">
                    <div class="button" @click="allSelect">{{ allSelecStatus ? $t('exhibitor.button_all_cancel') : $t('exhibitor.button_all')}}</div>
                    <div class="button" @click="addSelectData">{{$t('exhibitor.button_add')}}</div>
                </div>
            </div>
        </Block>

        <Block>
            <div class="toy-list">
                <div class="toy-item" v-for="(item, i) in detail.toy" :key="i">
                    <div class="img">
                        <div class="select">
                            <el-checkbox size="medium" v-model="item.checked" @change="select"></el-checkbox>
                        </div>
                        <el-image class="img pointer" fit="contain" lazy :src="item.main_picture" @click.native="jump('/goodsinfo', {id: item.toy_id})" :title="getLanguageData({zh: item.toy_name, en: item.toy_name_en}) + '（' + item.article_number + '）'">
                        </el-image>
                        <div class="video-icon" v-if="item.is_video == 1">
                            <img :src="require('@/assets/img/video.png')" />
                        </div>
                    </div>
                    <div class="toy-bottom">
                        <div class="left">
                            <div class="name ellipsis pointer" @click="jump('/goodsinfo', {id: item.toy_id})" :title="getLanguageData({zh: item.toy_name, en: item.toy_name_en})">{{ getLanguageData({zh: item.toy_name, en: item.toy_name_en}) }}</div>
                            <div class="number">
                                <div class="label">{{$t('exhibitor.article_number')}}</div>
                                <div class="text ellipsis" :title="item.article_number">{{item.article_number}}</div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="car" @click="addCart([item.toy_id])" v-if="item.cart == 0">
                                <img :src="require('@/assets/img/icon/cart1.png')" />
                            </div>
                            <div class="car" @click="delCart([item.cart])" v-else>
                                <img :src="require('@/assets/img/icon/del.png')" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="toy-item-placeholder"></div>
                <div class="toy-item-placeholder"></div>
                <div class="toy-item-placeholder"></div>
                <div class="toy-item-placeholder"></div>
                <div class="toy-item-placeholder"></div>
            </div>
        </Block>
        <!-- <Block>
            <a :href="detail.xiaoniao_id>0 ? 'http://www.toysaas.com/factory_detail/'+detail.xiaoniao_id : 'http://www.toysaas.com/'" target="_blank" class="xn-url">
                <div class="more-toy">
                    <div class="explain">{{$t('exhibitor.more_toy')}}</div><img :src="require('@/assets/img/more.png')" />
                </div>
            </a>
        </Block> -->
        <video-dialog v-model="videoPlay" :url="getLanguageData({zh: detail.video, en: detail.video_en})"></video-dialog>
    </Main>
</template>

<script>
import { read } from "@/api/exhibitor.js";
import { del } from '@/api/cart.js';
import Main from '@/components/layout2/layout2-main.vue';
import Header from '@/components/layout2/layout2-header.vue';
import Footer from '@/components/layout2/layout2-footer.vue';
import Block from '@/components/layout2/layout2-block.vue';
import VideoDialog from './components/video-dialog.vue';
import { getLanguageData, isEmpty, jump } from "@/libs/tools.js";
import { getLanguage } from "@/lang/index.js";
import Breadcrumb from "@/components/Breadcrumb"

export default {
    name: 'register',
    components: {
        Main,
        Header,
        Footer,
        Block,
        VideoDialog,
        Breadcrumb
    },
    data() {
        return {
            lang: getLanguage(),
            id: '',
            detail: {
                toy: [],
            },
            videoPlay: false,
            allSelecStatus: false,
            to: [
                {
                    name: this.$t("common.title9"),
                    path: '/',
                    query: {}
                },
            ],
            tempSelect: [],
        }
    },
    computed: {

    },
    mounted() {
        // this.getData();
    },
    methods: {
        getLanguageData,
        jump,
        //设置选中数据
        setTempSelect(){
            this.tempSelect = [];
            for (let i = 0; i < this.detail.toy.length; i++) {
                let item = this.detail.toy[i];
                if (item.checked == true) {
                    this.tempSelect.push(item.toy_id);
                }
            }
        },
        //清空选中数据
        clearTempSelect(){
            this.tempSelect = [];
        },
        //恢复选中数据
        recoverSelect(){
            for (let i = 0; i < this.detail.toy.length; i++) {
                let item = this.detail.toy[i];
                if (this.tempSelect.indexOf(item.toy_id) != -1) {
                    this.$set(this.detail.toy[i], 'checked', true);
                }
            }
        },
        //初始化选择的false
        initSelectFalse(){
            for (let i = 0; i < this.detail.toy.length; i++) {
                this.$set(this.detail.toy[i], 'checked', false);
            }
        },
        /**
         * 获取详情
         * recoverSelect 是否恢复已选中的数据
         */
        getData(recoverSelect=true) {
            read({ id: this.id }).then(res => {
                if (res.code == 0) {
                    this.detail = res.data;
                    this.initSelectFalse();
                    if(recoverSelect && typeof(this.detail.toy) != 'undefined'){
                        this.recoverSelect();
                        this.select();
                    }else{
                        this.clearTempSelect();
                    }
                    
                    this.to = [
                        {
                            name: this.$t("common.title9"),
                            path: '/',
                            query: {}
                        },
                    ]
                    this.to.push({
                        name: getLanguageData({ zh: this.detail.company_name, en: this.detail.company_en_name }),
                    })
                    document.title = getLanguageData({ zh: this.detail.company_name, en: this.detail.company_en_name }) + '-' +
                        this.$t("common.title8");
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        allSelect() {
            let all = true;
            for (let i = 0; i < this.detail.toy.length; i++) {
                if (this.detail.toy[i].checked != true) {
                    all = false;
                    break;
                }
            }
            if (all) {
                this.allSelecStatus = false
                for (let i = 0; i < this.detail.toy.length; i++) {
                    this.$set(this.detail.toy[i], 'checked', false);
                }
            } else {
                this.allSelecStatus = true
                for (let i = 0; i < this.detail.toy.length; i++) {
                    // if(this.detail.toy[i].cart == 0){
                    this.$set(this.detail.toy[i], 'checked', true);
                    // }
                }
            }
            this.setTempSelect();
        },
        select() {
            let all = true
            for (let i = 0; i < this.detail.toy.length; i++) {
                if (this.detail.toy[i].checked != true) {
                    all = false;
                    break;
                }
            }
            if (all) {
                this.allSelecStatus = true
            } else {
                this.allSelecStatus = false
            }
            this.setTempSelect();
        },
        addSelectData() {
            let ids = [];
            for (let i = 0; i < this.detail.toy.length; i++) {
                let item = this.detail.toy[i];
                if (item.checked == true) {
                    ids.push(item.toy_id);
                }
            }
            //没选中不管他
            if (ids == 0) {
                for (let i = 0; i < this.detail.toy.length; i++) {
                    let item = this.detail.toy[i];
                    ids.push(item.toy_id);
                }
            };
            this.addCart(ids, false);
            this.allSelecStatus = false
        },
        //添加选样车
        addCart(ids, recoverSelect=true) {
            this.$store.dispatch('cart/add', { toy_id: ids }).then(res => {
                if (res.code == 0) {
                    this.getData(recoverSelect);
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        //已在购物车
        hasCart() {
            this.$message.warning(this.$t('common.hasCart'));
        },
        delCart(id) {
            del({ id: id }).then(res => {
                if (res.code == 0) {
                    this.getData();
                    this.$message.success(res.msg);
                    this.$store.dispatch('cart/getNum');
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    },
    watch: {
        $route: {
            handler() {
                this.id = this.$route.query.id;
                this.getData();
                //深度监听，同时也可监听到param参数变化
            },
            immediate: true,
            deep: true,
        }
    }
}
</script>
<style lang="less" scoped>
.background {
    background: #f6f6f6;
    min-height: 100vh;
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.pointer {
    cursor: pointer;
}
.detail {
    background-color: #ffffff;
    min-height: 150px;
    display: flex;
    padding: 20px 30px;
    box-sizing: border-box;
    .left {
        width: 140px;
        padding-right: 20px;
        .img {
            width: 140px;
            height: 140px;
        }
    }
    .content {
        flex: 1;
        padding-right: 28px;
        overflow: hidden;
        .line1 {
            margin-bottom: 20px;
            display: flex;
            align-items: flex-end;
            box-sizing: border-box;
            .name {
                max-width: 50%;
                font-size: 22px;
                font-weight: bold;
                color: #333333;
            }
            .booth {
                flex: 1;
                margin-left: 30px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                display: flex;
                overflow: hidden;
                .label {
                }
                .text {
                    min-width: 0;
                    flex: 1;
                    font-weight: bold;
                    color: #3053A3;
                }
            }
        }
        .line2 {
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 12px;
            display: flex;
            line-height: 18px;
            overflow: hidden;
            &:last-child {
                margin-bottom: 0;
            }
            .label {
                flex-shrink: 0;
                color: #999999;
            }
            .text {
                color: #333333;
            }
            .line2-left {
                display: flex;
                flex-shrink: 0;
                width: 200px;
                margin-right: 10px;
                overflow: hidden;
            }
            .line2-right {
                flex: 1;
                display: flex;
                overflow: hidden;
            }
        }
    }
    .right {
        width: 288px;
        height: 150px;
        border-left: 1px solid #ededed;
        padding-left: 20px;
        box-sizing: border-box;
        .video {
            position: relative;
            .play {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                img {
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                }
            }
        }
    }
}
.operate {
    margin-top: 10px;
    height: 58px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button-group {
        margin-right: 30px;
        display: flex;
        .button {
            cursor: pointer;
            min-width: 90px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border: 1px solid #3053A3;
            border-radius: 2px;

            font-size: 13px;
            font-weight: 400;
            color: #3053A3;

            box-sizing: border-box;
            padding-left: 10px;
            padding-right: 10px;
            margin-left: 14px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
.toy-list {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    margin-bottom: 50px;
    .toy-item-placeholder {
        width: 228px;
    }
    .toy-item {
        width: 228px;
        height: 227px;
        background: #ffffff;
        box-sizing: border-box;
        padding: 10px;
        margin-top: 15px;
        &:nth-child(-n + 5) {
            //前5个
            margin-top: 0;
        }
        .img {
            width: 100%;
            height: 156px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            .select {
                position: absolute;
                left: 4px;
                top: 0px;
                /deep/ .el-checkbox__inner {
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                }
                /deep/ .el-checkbox__inner::after {
                    height: 8px;
                    width: 4px;
                }
            }
            .video-icon {
                position: absolute;
                right: 4px;
                bottom: 4px;
                width: 40px;
                height: 22px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .toy-bottom {
            margin-top: 10px;
            display: flex;
            .left {
                flex: 1;
                overflow: hidden;
                .name {
                    font-size: 14px;
                    font-weight: bold;
                    color: #333333;
                    &:hover {
                        color: #3053A3;
                    }
                }
                .number {
                    margin-top: 6px;
                    font-size: 12px;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    .label {
                        color: #999999;
                    }
                    .text {
                        flex: 1;
                        color: #666666;
                    }
                }
            }
            .right {
                width: 36px;
                .car {
                    width: 36px;
                    height: 36px;
                    // background: rgba(207, 0, 13, 0.1);
                    // border-radius: 50%;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    cursor: pointer;
                    margin-top: 2px;
                }
            }
        }
    }
}
.xn-url {
    text-decoration: none;
}
.more-toy {
    margin-top: 10px;
    margin-bottom: 50px;
    width: 100%;
    height: 58px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .explain {
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        color: #999999;
        margin-right: 6px;
    }
    img {
        width: 14px;
        height: 14px;
    }
}
</style>
