<template>
  <div class="crumbcontarner">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in to" :key="index">
        <span
          class="hovercolor"
          @click="gorouter(item.path, item.query)"
        >
          {{item.name}}
        </span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { jump } from "@/libs/tools.js";
import { getLanguageData } from "@/libs/tools.js";
export default {
  components: {},
       props: ['to'],  
      
  data() {
    return {

    };
  },
  computed: {},
  methods: {
    jump,
   getLanguageData,
   gorouter(path,query){
        if(path){
           jump(path,query)
        }
   }
  },
  created() {},
  mounted() {
  },
};
</script>
<style lang="less"  scoped>
.hovercolor:hover {
  color: #3053A3;
}
::v-deep .el-breadcrumb{
  font-size: 12px;
  color: #999999;
}
.crumbcontarner {
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #999999;
  opacity: 1;
}
.breadcrumb:hover {
  cursor: pointer;
}
::v-deep .el-breadcrumb__separator{
  margin: 0 6px;
  color: #999999;
}
</style>