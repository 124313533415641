import axios from '@/libs/axios';
import { Exhibitor } from './variable.js';

//参展商主页
export const read = (data) => {
    return axios.request({
        url: Exhibitor.read,
        data: data,
        method: 'post'
    });
};