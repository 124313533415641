<template>
    <el-dialog class="video-dialog" :title="$t('exhibitor.video_title')" :destroy-on-close="true" :show-close="true" :close-on-click-modal="false" :append-to-body="true":visible.sync="dialogVisible" width="800px">
        <video v-if="dialogVisible" width="100%" height="446" controls :autoplay="true">
            <source :src="url" type="video/mp4">
            {{$t('exhibitor.video_nonsupport')}}
        </video>
    </el-dialog>
</template>

<script>
import { getLanguageData, isEmpty } from "@/libs/tools.js";
import { getLanguage } from "@/lang/index.js";

export default {
    name: 'video-dialog',
    components: {

    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        url:{
            type: String,
            default: ''
        }
    },
    data() {
        return {
            lang: getLanguage(),
        }
    },
    mounted() {

    },
    methods: {
        getLanguageData,
    },
    computed: {
        dialogVisible: {
            set(val) {
                this.$emit('input', val);
            },
            get() {
                return this.value;
            }
        },
    },
    watch: {
        
    }
}
</script>
<style lang="less" scoped>
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.video-dialog {
    /deep/ .el-dialog {
        border-radius: 10px;
    }
    /deep/ .el-dialog__header {
        height: 49px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eaeaea;
        .el-dialog__title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
        }
    }
    /deep/ .el-dialog__body {
        padding: 10px;
    }
}
</style>
